import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import {
  defaultTheme, // Default theme
} from '@ant-design/compatible';
import ConfigProvider from 'antd/es/config-provider';

const IndexContainer = loadable(() =>
  import(/* webpackChunkName: "IndexContainer" */ './containers/application/IndexContainer'),
);

const EventShowContainer = loadable(() => import(/* webpackChunkName: "Events" */ './containers/events/ShowContainer'));

const CommunityEditContainer = loadable(() =>
  import(/* webpackChunkName: "Communities" */ './containers/communities/EditContainer'),
);

const CommunityShowContainer = loadable(() =>
  import(/* webpackChunkName: "Communities" */ './containers/communities/ShowContainer'),
);

const CommunityMembershipIndexContainer = loadable(() =>
  import(/* webpackChunkName: "CommunityMemberships" */ './containers/communityMemberships/IndexContainer'),
);

const CommunityTeamCreateContainer = loadable(() =>
  import(/* webpackChunkName: "CommunityTeams" */ './containers/communityTeams/CreateContainer'),
);

const OrganisedEditContainer = loadable(() =>
  import(/* webpackChunkName: "OrganisedEvents" */ './containers/organisedEvents/EditContainer'),
);

const OrganisedDashboardContainer = loadable(() =>
  import(/* webpackChunkName: "OrganisedEvents" */ './containers/organisedEvents/DashboardContainer'),
);

const MeTeamProgressContainer = loadable(() =>
  import(/* webpackChunkName: "Me" */ './containers/me/TeamProgressContainer'),
);

const ECertificatesShowContainer = loadable(() =>
  import(/* webpackChunkName: "ECertificates" */ './containers/eCertificates/ShowContainer'),
);

const MeAccountDeletionContainer = loadable(() =>
  import(/* webpackChunkName: "AccountDeletion" */ './containers/accountDeletion/IndexContainer'),
);

const App = (props) => (
  <React.StrictMode>
    <ConfigProvider
      theme={{
        ...defaultTheme,
        token: {
          colorPrimary: '#25BCB5',
          colorInfo: '#25BCB5',
          colorText: '#454F57',
          colorTextSecondary: '#6f7981',
          colorTextHeading: '#454F57',
          borderRadius: 4,
        },
      }}
    >
      <Router>
        <Routes>
          <Route path='/' element={<IndexContainer {...props} />} />
          <Route path='/me/account_deletion' element={<MeAccountDeletionContainer {...props} />} />
          <Route path='/events/:id' element={<EventShowContainer {...props} />} />
          <Route path='/me/communities/:id/edit' element={<CommunityEditContainer {...props} />} />
          <Route path='/me/communities/:id' element={<CommunityShowContainer {...props} />} />
          <Route
            path='/me/communities/:id/community_memberships'
            element={<CommunityMembershipIndexContainer {...props} />}
          />
          <Route path='/me/communities/:id/community_teams/new' element={<CommunityTeamCreateContainer {...props} />} />
          <Route path='/me/organised_events/:id/edit' element={<OrganisedEditContainer {...props} />} />
          <Route path='/me/organised_events/:id/dashboard' element={<OrganisedDashboardContainer {...props} />} />
          <Route path='/me/events/:id/progress' element={<MeTeamProgressContainer {...props} />} />
          <Route path='/e_certs/:id' element={<ECertificatesShowContainer {...props} />} />
        </Routes>
      </Router>
    </ConfigProvider>
  </React.StrictMode>
);

export default App;
